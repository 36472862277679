import React, { useState } from 'react'
import logo from '../images/logo1.PNG'
import Navbar from './Navbar'
import Footer from './Footer'


const Register = () => {
    // States for registration
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');

    // States for checking the errors
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    // Handling the name change
    const handleName = (e) => {
        setName(e.target.value);
        setSubmitted(false);
    };
    // Handling the phone change
    const handlePhone = (e) => {
        setPhone(e.target.value);
        setSubmitted(false);
    };


    // Handling the email change
    const handleEmail = (e) => {
        setEmail(e.target.value);
        setSubmitted(false);
    };

    // Handling the password change
    const handlePassword = (e) => {
        setPassword(e.target.value);
        setSubmitted(false);
    };

    // Handling the form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (name === '' || phone === '' || email === '' || password === '') {
            setError(true);
        } else {
            setSubmitted(true);
            setError(false);
        }
    };

    // Showing success message
    const successMessage = () => {
        return (
            <div
                className="success"
                style={{
                    display: submitted ? '' : 'none',
                }}>
                <h4 style={{color:'green'}}>User {name} successfully registered!!</h4>
            </div>
        );
    };
    const errorMessage = () => {
        return (
          <div
            className="error"
            style={{
              display: error ? '' : 'none',
            }}>
            <h3 style={{color:'red'}}>Please enter all the fields.</h3>
          </div>
        );
      };
    return (
        <>            
            <Navbar />
            <section className="" style={{ color: "#eee" }}>
                <div className="container h-50">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-xl-5">
                            <div className="card text-black">
                                <div className="row">
                                    <div className="card-body p-md-3 mx-md-4">
                                        <div className="text-center">
                                            <img src={logo} style={{ width: "185px" }} alt="logo" />
                                            <h4 className="mt-1 mb-3 pb-1">We are The Dev Team</h4>
                                        </div>
                                        <div className="messages">
                                            {errorMessage()}
                                            {successMessage()}
                                        </div>
                                        <form>
                                            <div className="form-outline mb-3">
                                                <label className="form-label" for="form2Example22">Full Name</label>

                                                <input onChange={handleName} type="text" placeholder="Your Full Name" id="form2Example22" value={name}className="form-control" />
                                            </div>
                                            <div className="form-outline mb-3">
                                                <label className="form-label" for="form2Example11">Email</label>

                                                <input onChange={handleEmail} type="email" value={email} id="form2Example11" className="form-control"
                                                    placeholder="Email address" />
                                            </div>
                                            <div className="form-outline mb-3">
                                                <label className="form-label" for="form2Example22">Phone</label>

                                                <input onChange={handlePhone} type="number" value={phone} placeholder="Your Phone" id="form2Example22" className="form-control" />
                                            </div>

                                            <div className="form-outline mb-3">
                                                <label className="form-label" for="form2Example22">Password</label>

                                                <input onChange={handlePassword} type="password" value={password} placeholder="Your Password" id="form2Example22" className="form-control" />
                                            </div>

                                            <div className="form-outline mb-3">
                                                <label className="form-label" for="form2Example22">Confirm Password</label>
                                                <input type="password" placeholder="Confirmed Password" id="form2Example22" className="form-control" />

                                            </div>
                                            <div className="d-flex align-items-center justify-content-center pb-4 mb-2">
                                                <button onClick={handleSubmit} type="submit" className="btn btn-outline-success">Sign up</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Register

import './App.css';
import Skills from './components/Skills';
import { BrowserRouter as Router, Routes,Route } from "react-router-dom";
// import Navbar from './components/Navbar';
import Home from './components/Home';
import Login from './components/Login';
import Blog from './components/Blog';
import Register from './components/Register';
import Maps from './components/Maps';
import Products from './components/Products';
import EffectsParticles from './components/EffectsParticles';
import Contact from './components/Contact';


function App() {
  return (
    <>       
    <Router>
    <EffectsParticles/>
      <Routes>           
        <Route path="/" element={<Home />} />
        <Route path='/projects' element={<Skills/>}/> 
        <Route path='/admin' element={<Login/>}/>
        <Route path='/blogs' element={<Blog />}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/maps' element={<Maps/>}/>
        <Route path='/products' element={<Products/>}/>
      </Routes>     
    </Router>
   </>
  );
}

export default App;

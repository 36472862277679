/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Navbar from './Navbar'
import { projects } from '../dev-data/Projects'

const Skills = () => {
    return (
        <> 
         < Navbar />
            <div className='container'>
                <div className="row">
                    {projects.map((skill) => (
                        <div className="col-sm-4" key={skill.id}>
                            <div  className="card mb-3 ">
                                <img src={skill.image} alt="Project"  />
                                <div className="card-body" >                                    
                                    <h3 className="card-title">{skill.title}</h3>
                                    <h5 className="card-subtitle mb-2 text-muted">{skill.subtitle}</h5>
                                    <p className="card-text">{skill.description}</p>
                                    <a href={skill.link} className="btn btn-primary" target="_blank" rel="noreferrer">Go To Site</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Skills
import React from 'react'
import Navbar from './Navbar'
import Typewriter from 'typewriter-effect';
import Footer from './Footer';

const Home = () => {

    return (
        <>            
            <Navbar />            
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-sm-6 mb-4'>
                    <div className='home'>
                        <h2 className='pname'>Navin Paudel</h2>
                        <h3 >I'm <span>
                            <Typewriter options={{
                                strings: [' MERN Developer ', ' Self-Learner ', ' Tech-enthusiast '],
                                autoStart: true,
                                loop: true,
                                cursor: ' |',
                            }} />
                        </span>
                        </h3>
                        
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='card card-1 text-bg-dark'>
                        <div className="card-header text-center text-uppercase">
                            Skills
                        </div>
                        <div className='row row-cols-2 p-4 row-cols-md-2 row-cols-lg-4'>
                            <div className='col mb-3'>
                                <button type="button" className="btn btn-primary">HTML</button>
                            </div>
                            <div className='col mb-3'>
                                <button type="button" className="btn btn-primary">CSS</button>
                            </div>
                            <div className='col mb-3'>
                                <button type="button" className="btn btn-primary">Javascript</button>
                            </div>
                            <div className='col mb-3'>
                                <button type="button" className="btn btn-primary">Reactjs</button>
                            </div>
                            <div className='col mb-3'>
                                <button type="button" className="btn btn-primary">Nodejs</button>
                            </div>
                            <div className='col mb-3'>
                                <button type="button" className="btn btn-primary">MongoDB</button>
                            </div>
                            <div className='col mb-3'>
                                <button type="button" className="btn btn-primary px-1">Expressjs</button>
                            </div>
                            <div className='col mb-3'>
                                <button type="button" className="btn btn-primary">MySQL</button>
                            </div>
                            <div className='col mb-3'>
                                <button type="button" className="btn btn-primary">PHP</button>
                            </div>
                        </div>
                    </div>
                </div> 
                </div>               
            </div>
            <Footer/>
        </>
    );
};

export default Home
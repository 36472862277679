import React from 'react'

const Footer = () => {
    const getYear=()=> {
        return new Date().getFullYear();
    }
    return (
        <>
            <footer className="d-flex flex-wrap justify-content-between bg-dark align-items-center mt-4">
                <div className="col-md-4 d-flex align-items-center">
                    <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                        {/* <svg className="bi" width="30" height="24"><use xlink:href="#bootstrap" /></svg> */}
                    </a>
                    <span className="mb-3 mb-md-0 text-white p-4">&copy; {getYear()} Navin Paudel</span>
                </div>

                <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="ms-3"><a className="text-muted" href="/">
                        {/* <svg className="bi" width="24" height="24"><use xlink:href="#twitter" /></svg> */}
                        </a></li>
                    <li className="ms-3"><a className="text-muted" href="/">
                        {/* <svg className="bi" width="24" height="24"><use xlink:href="#instagram" /></svg> */}
                        </a></li>
                    <li className="ms-3"><a className="text-muted" href="/">
                        {/* <svg className="bi" width="24" height="24"><use xlink:href="#facebook" /></svg> */}
                        </a></li>
                </ul>
            </footer>
        </>
    )
}

export default Footer
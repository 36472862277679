import React from 'react'
import Navbar from './Navbar'

const Products = () => {
  return (
    <>
        <Navbar />
        <h1 style={{color:'red'}}>Coming soon!!</h1>
    </>
  )
}

export default Products
import React from 'react'
import Particles from 'react-tsparticles'
import particlesConfig from '../dev-data/configParticles.js'
import { loadFull } from 'tsparticles';

const EffectsParticles = () => {
    const particlesInit = async (main) => {
        // console.log(main);
        await loadFull(main);
    };
    
    return (
        <>
            <Particles id="tsparticles" params={particlesConfig} init={particlesInit}/>

        </>
    )
}

export default EffectsParticles
import React,{ useRef } from 'react'
import emailjs from '@emailjs/browser';
import Navbar from './Navbar'

const Contact = () => {
    const form = useRef();
   
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wsvqcih', 'template_88vhll9', form.current, 'dT7z761ygE9U90Smm')
      .then((result) => {
          console.log(result.text);
          alert("Message sent successfully.Thank you for you message.")
      }, (error) => {
          console.log(error.text);
      });
  };
    return (
        <>
            <Navbar />
            <form ref={form} onSubmit={sendEmail}>
            <div className='d-flex justify-content-center'>
            <div className='card p-3' style={{width:"450px"}}>
                <h2 className='m-0'>Leave Your Message</h2>
                <hr/>
                <div className="mb-3">
                    <label  className="form-label">Full Name</label>
                    <input type="text" className="form-control" name="user_name" placeholder="Jonh Deo" required />
                </div>
                <div className="mb-3">
                    <label  className="form-label">Email address</label>
                    <input type="email" className="form-control" name="user_email" placeholder="jonhdeo@example.com" required/>
                </div>
                <div className="mb-3">
                    <label  className="form-label">Message</label>
                    <textarea className="form-control" name="message" rows="3" required></textarea>
                </div>
                <button type='submit' className='btn btn-warning text-white text-uppercase'>Submit</button>
            </div>
            </div>
            </form>
        </>
    )
}

export default Contact
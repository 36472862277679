/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import logo from '../images/logo1.PNG'
import { useNavigate } from 'react-router-dom';
import Footer from './Footer'

const Login = () => {
    // const newRegister = () => {
    //     window.location.href = '/register'
    // };
    const navigate = useNavigate();

    const newRegister = () => {
        // 👇️ navigate to /contacts
        navigate('/register');
    };

    //   const navigateHome = () => {
    //     // 👇️ navigate to /
    //     navigate('/');
    //   };

    return (
        <>
            <Navbar />
            <section class="h-60" style={{ color: "#eee" }}>
                <div class="container h-50">
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-xl-5">
                            <div class="card text-black">
                                <div class="row">
                                    <div class="card-body p-md-3 mx-md-4">
                                        <div class="text-center">
                                            <img src={logo} style={{ width: "185px" }} alt="logo" />
                                            <h4 class="mt-1 mb-3 pb-1">We are The Dev Team</h4>
                                        </div>
                                        <form>
                                            <p>Please login to your account</p>
                                            <div class="form-outline mb-3">
                                                <input type="email" id="form2Example11" class="form-control"
                                                    placeholder="Email address" />
                                                <label class="form-label" for="form2Example11">User Name</label>
                                            </div>
                                            <div class="form-outline mb-3">
                                                <input type="password" placeholder="Your Password" id="form2Example22" class="form-control" />
                                                <label class="form-label" for="form2Example22">Password</label>
                                            </div>
                                            <div class="text-center pt-1 mb-4 pb-1">
                                                <button class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3 me-3" type="button">Log in</button>
                                                <Link class="text-muted" to="#!">Forgot password?</Link>
                                            </div>

                                            <div class="d-flex align-items-center justify-content-center pb-4 mb-3">
                                                <p class="mb-0 me-2">Don't have an account?</p>
                                                <button onClick={newRegister} type="button" class="btn btn-outline-danger">Create new</button>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Login
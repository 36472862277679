import React from 'react'
import Navbar from './Navbar'


const Maps = () => {
    
    return (
        <>
            <Navbar />
            
              <h1 style={{color:'red'}}>Coming soon!!</h1>
        </>
    )
}

export default Maps
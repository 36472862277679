import React from 'react'
import Navbar from './Navbar'


const Blog = () => {
  return (
    <>
        <Navbar />        
        {/* <div className='container'>
                <div class="row">
                    {blogs.map((blog) => (
                        <div class="col-sm-6">
                            <div key={blog} class="card mb-3 ">
                                <div class="card-body">
                                    <img src={blog.image} alt="Project" class="img-fluid" />
                                    <h3 class="card-title">{blog.title}</h3>
                                    <h5 class="card-subtitle mb-2 text-muted">{blog.subtitle}</h5>
                                    <p class="card-text">{blog.description}</p>
                                    <a href={blog.link} class="btn btn-primary">More..</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
        </div> */}
        <h1 style={{color:'red'}}>Coming soon!!</h1>
    </>
    
  )
}

export default Blog